/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${toAbsoluteUrl('/media/lge/loginbg.jpg')})`
    document.body.style.backgroundRepeat = 'no-repeat'
    document.body.style.backgroundPosition = 'center center'
    document.body.style.backgroundSize = 'cover'

    // Create a semi-transparent overlay layer using the ::after pseudo-element
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.background = 'rgba(0, 0, 0, 0.75)'; // Adjust opacity here
    overlay.style.zIndex = '1';

    // Append the overlay to the body
    document.body.appendChild(overlay);
    return () => {
      function cleanupBackground(){
        // Clean up the overlay element when the component unmounts
        document.body.style.backgroundImage = '';
        document.body.style.backgroundRepeat = '';
        document.body.style.backgroundPosition = '';
        document.body.style.backgroundSize = '';
        document.body.removeChild(overlay);
      };
      cleanupBackground()
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        zIndex: '2'
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default-dark.svg')}
            className='theme-dark-show h-105px'
          />
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/lge/logow.png')}
            className='theme-light-show h-125px'
          ></img>
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-semibold fs-6'>
          <a href='https://laisegobira.com' className='text-muted text-hover-primary px-2'>
            Sobre nós
          </a>
          <a href='https://wa.me/message/P66EPYDCK65OM1' rel='noreferrer' target='_blank' className='text-muted text-hover-primary px-2'>
            Contato
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
